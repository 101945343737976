<template>
  <div class="main-container">
    <div class="section-topic">
      <div class="title-header">
        <h1>
          {{ $t("Employee.title")
          }}<span class="small-title">({{ pagination.total }} {{ $t("Employee.form.people") }})</span>
        </h1>
      </div>
    </div>
    <div class="section-action">
      <div class="search-action">
        <v-row dense>
          <v-col cols="12" md="2" lg="2" class="search">
            <label class="label-input">{{ $t("Employee.search_filter") }}</label>
            <v-text-field class="btn btn-color btn-border btn-border-color" outlined dense
              prepend-inner-icon="mdi-magnify" placeholder="..." v-model="searchItem" @keypress.enter="searchFilterItem"
              hide-details>
            </v-text-field>
          </v-col>
          <v-col cols="12" md="2" lg="2">
            <label class="label-input">{{ $t("Employee.table.role") }}</label>
            <v-select :items="role_items" outlined dense item-value="id"
              :item-text="item => $t(`Employee.table.${item.name}`)" v-model="employee_role"
              @change="fetchEmployee"></v-select>
          </v-col>
          <v-col cols="12" md="2" lg="2">
            <label class="label-input">{{ $t("Employee.branch") }}</label>
            <v-autocomplete filled dense item-text="branch_name" item-value="id" outlined :items="listBranch"
              hide-selected v-model="branch_id" @change="handleBranch($event)">
              <template v-slot:item="data">
                <template>
                  <v-list-item-content>
                    <v-list-item-title v-html="data.item.branch_name"></v-list-item-title>
                  </v-list-item-content>
                </template>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="2" lg="2">
            <label class="label-input">{{ $t("Employee.dept") }}</label>
            <v-autocomplete filled dense item-text="name" item-value="id" outlined :items="listDepartment" hide-selected
              v-model="department_id" @change="handleDepartment($event)">
              <template v-slot:item="data">
                <template>
                  <v-list-item-content>
                    <v-list-item-title v-html="data.item.name"></v-list-item-title>
                  </v-list-item-content>
                </template>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="2" lg="2">
            <label class="label-input">{{ $t("Employee.start_date") }}</label>
            <DatePicker class="date-time" style="width: 100%" type="date" valueType="format" slot="activator"
                :append-to-body="true" requried persistent-hint v-model="start_date" @change="searchFilterItem">
              </DatePicker>
          </v-col>
          <v-col cols="12" md="2" lg="2">
            <label class="label-input"></label>
            <br>
            <DatePicker class="date-time" style="width: 100%" type="date" valueType="format" slot="activator"
                :append-to-body="true" requried persistent-hint v-model="start_date" @change="searchFilterItem">
              </DatePicker>
          </v-col>
          <!-- <v-col cols="12" md="2" lg="4">
            <label class="label-input">{{ $t("Employee.start_date") }}</label>
            <div class="d-flex justify-space-between">
              <DatePicker class="date-time" style="width: 48%" type="date" valueType="format" slot="activator"
                :append-to-body="true" requried persistent-hint v-model="start_date" @change="searchFilterItem">
              </DatePicker>
              <DatePicker class="date-time" style="width: 48%" type="date" valueType="format" slot="activator"
                :append-to-body="true" requried persistent-hint v-model="end_date" @change="searchFilterItem">
              </DatePicker>
            </div>
          </v-col> -->
        </v-row>
      </div>
    </div>
    <div class="section-action">
      <div class="mt-6 section-btn lfont">
        <v-btn class="btn-create mr-4" @click="onCreate()">
          <span><i class="fal fa-plus" style="margin-right: 4px"></i></span>{{ $t("Employee.create") }}
        </v-btn>
        <v-btn class="btn-export mr-4" @click="exportExcel()">
          <span><i class="fal fa-file-export" style="margin-right: 4px"></i></span>Export Excel
        </v-btn>
        <v-btn class="btn-import mr-4" @click="importExcel()">
          <span><i class="fal fa-file-export" style="margin-right: 4px"></i></span>Import Excel
        </v-btn>
      </div>
    </div>
    <div class="section-form-content">
      <div class="block-content">
        <table ref="employeeExport" class="table table-view" v-if="listEmployee.length > 0">
          <thead>
            <tr>
              <th>Actions</th>
              <th>
                {{ $t("Employee.table.no") }}
              </th>
              <th>
                {{ $t("Employee.table.Emp_ID") }}
              </th>
              <th>
                {{ $t("Employee.table.image") }}
              </th>
              <th class="text-left">
                {{ $t("Employee.table.name") }}
              </th>
              <th class="text-left">Name</th>
              <th class="text-left">
                {{ $t("Employee.table.email") }}
              </th>
              <th>{{ $t("Employee.table.role") }}</th>
              <!-- <th class="text-left">
                {{ $t("Employee.table.province") }}
              </th>
              <th class="text-left">
                {{ $t("Employee.table.district") }}
              </th>
              <th class="text-left">
                {{ $t("Employee.table.village") }}
              </th> -->
              <th class="text-left" v-if="ManageSalaryPermission">
                {{ $t("Employee.form.baseSalary") }}
              </th>
              <th class="text-left">
                {{ $t("Employee.table.type") }}
              </th>
              <th class="text-left">
                {{ $t("Employee.table.status") }}
              </th>
              <th class="text-left"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, idx) in listEmployee" :key="idx" :class="{ 'employee-exit': item.status === 'pending' }">
              <td class="text-right">
                <v-menu transition="slide-y-transition" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn outlined v-bind="attrs" v-on="on">
                      <i class="fas fa-ellipsis-v btn-ellipsis"></i>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item>
                      <v-list-item-title class="btn-edit" @click="onEdit(item.id)">Edit
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title class="btn-edit" @click="onUpdateStatus(item.id)">Update Status
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title class="btn-delete" @click="onDeleted(item.id)">Delete
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
              <td>{{ pagination.current_page * 10 - 10 + idx + 1 }}</td>
              <td>{{ item.emp_number }}</td>
              <td @click="showAllEmployeeItem(item)" style="cursor: pointer">
              <!-- <td @click="console.log(item.emp_number)" style="cursor: pointer"> -->
                <vs-tooltip v-if="item.status === 'pending'" danger>
                  <div v-if="item.urlProfile" class="img-table-company">
                    <img :src="item.urlProfile" alt="" />
                  </div>
                  <div v-else class="demo-profile">
                    <i class="fal fa-user-alt"></i>
                  </div>
                  <template #tooltip>
                    <span class="tooltip-la">ພະນັກງານກຽມອອກວຽກ</span>
                  </template>
                </vs-tooltip>
                <div v-else>
                  <div v-if="item.urlProfile" class="img-table-company">
                    <img :src="item.urlProfile" alt="" />
                  </div>
                  <div v-else class="demo-profile">
                    <i class="fal fa-user-alt"></i>
                  </div>
                </div>
              </td>
              <td class="employee-name">
                <div>
                  {{ item.name }} <span>{{ item.surname }}</span>
                  <div v-if="item.joinPositions.length" class="position">
                    <span>
                      {{ item.joinPositions[0].name }}
                      <span v-if="item.joinPositions.length > 1" class="length-position"
                        @click="showAllEmployeeItem(item)">+{{ item.joinPositions.length - 1 }}</span>
                    </span>
                  </div>
                </div>
              </td>
              <td>{{ item.en_name }} {{ item.en_surname }}</td>
              <td>
                {{ item.joinUser.email }}
              </td>
              <td>
                <p v-for="(role_item, k) in item.joinRoleUsers"><v-icon small color="green darken-2">
                    mdi-account
                  </v-icon> {{ $t(`Employee.table.${role_item.name}`) }}</p>
              </td>

              <!-- <td>
                <div v-if="item && item.joinAdress">
                  <div>
                    {{ item.joinAdress.province_name }}
                  </div>
                </div>
              </td>
              <td>
                <div v-if="item && item.joinAdress">
                  <div>
                    {{ item.joinAdress.district_name }}
                  </div>
                </div>
              </td>
              <td>
                <div v-if="item && item.joinAdress">
                  <div>
                    {{ item.joinAdress.village }}
                  </div>
                </div>
              </td> -->

              <td v-if="ManageSalaryPermission">
                <div v-if="item && item.joinBaseSalary">
                  <div>
                    {{
                      $helpers.numberFormatter(item.joinBaseSalary.base_salary)
                    }}
                  </div>
                </div>
              </td>
              <td>
                <div>
                  <span class="fullTime" v-if="item.employment_type === 'FullTime'"><i class="fas fa-circle"></i>{{
                    item.employment_type }}</span>
                  <span class="partTime" v-else><i class="fas fa-circle"></i>{{ item.employment_type }}</span>
                </div>
              </td>
              <td>
                <div>
                  <span class="fullTime" v-if="item.employment_status === 'Permanent'"><i class="fas fa-circle"></i>{{
                    item.employment_status }}</span>
                  <span class="partTime" v-else><i class="fas fa-circle"></i>{{ item.employment_status }}</span>
                </div>
              </td>
              <td class="text-end">
                <v-menu transition="slide-y-transition" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <vs-tooltip style="float: right" ref="addTooltip">
                      <v-btn @click="manageEmployee(item.id)" outlined v-bind="attrs" v-on="on">
                        <i class="fal fa-book-reader" style="font-size: 22px; color: #878787"></i>
                      </v-btn>
                      <template #tooltip> skill,education...</template>
                    </vs-tooltip>
                  </template>
                </v-menu>
              </td>
              <td class="text-right" v-if="item.joinResigned === null">
                <v-menu transition="slide-y-transition" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="label-input" style="color: black" outlined v-bind="attrs" v-on="on"
                      @click="onChangeEmployeeStatus(item.id)">
                      <i class="fas fa-pen"></i>
                      {{ $t("Employee.table.resigned") }}
                    </v-btn>
                  </template>
                </v-menu>
              </td>
              <td class="text-right" v-else>
                <div class="leave-soon" @click="onChangeEmployeeToActive(item.joinResigned.id)">
                  {{ $t("Employee.table.prepare_leave") }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <defaultTableNoResult v-else />
        <Pagination v-if="pagination.total_pages > 1" :pagination="pagination" :offset="offset"
          @paginate="fetchEmployee">
        </Pagination>
        <ModalDelete>
          <template v-slot="{ close }">
            <DeleteEmployee :employeeId="employeeId" @close="close" @success="fetchEmployee" />
          </template>
        </ModalDelete>
        <ModalConfirm>
          <template v-slot="{ close }">
            <UpdateStatusEmployee :employeeId="employeeId" @close="close" @success="fetchEmployee" />
          </template>
        </ModalConfirm>

        <showAllEmployeeItem :visible="modalShowAllEmployeeItem" :employeeItem="employee"
          @change="(val) => (modalShowAllEmployeeItem = val)" />

        <updateEmployeeStatus :visible="modalUpdateEmployeeStatus" :employeeId="employeeId" @success="fetchEmployee"
          @change="(val) => (modalUpdateEmployeeStatus = val)" />

        <employeePendingToActive :visible="modalUpdateEmployeeStatusToActive" :resigned_id="resigned_id"
          @success="fetchEmployee" @change="(val) => (modalUpdateEmployeeStatusToActive = val)" />

        <Loading v-if="isLoading" />
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import DeleteEmployee from "@/components/employee/from/DeleteEmployee";
import UpdateStatusEmployee from "@/components/employee/from/UpdateStatusEmployee";
import Loading from "@/components/Loading";
import Pagination from "@/components/Paginate/Pagination";
import showAllEmployeeItem from "./showAllEmployeeItem";
import defaultTableNoResult from "@/components/defaultTableNoResult";
import updateEmployeeStatus from "@/components/employee/stepsEdit/employeeStatus";
import employeePendingToActive from "@/components/employee/modal/modalUpdateEmployeeStatus";
import { colors } from "vuetify/lib";

// import XLSX from "xlsx";
export default {
  components: {
    DatePicker,
    DeleteEmployee,
    UpdateStatusEmployee,
    Loading,
    Pagination,
    showAllEmployeeItem,
    defaultTableNoResult,
    updateEmployeeStatus,
    employeePendingToActive,
  },

  data() {
    return {
      data: {},
      ManageSalaryPermission: false,
      modalShowAllEmployeeItem: false,
      modalUpdateEmployeeStatus: false,
      modalUpdateEmployeeStatusToActive: false,
      searchItem: "",
      offset: 10,
      pagination: {},
      per_page: 10,

      listEmployee: [],
      employee: {},
      employeeId: "",
      resigned_id: "",
      isLoading: false,
      btnLoading: false,
      department_id: "",
      listDepartment: [],
      listBranch: [],
      branch_id: "",
      start_date: "",
      end_date: "",
      role_items: [
        {
          id: 8,
          name: 'hrmanager'
        },
        {
          id: 7,
          name: 'linemanager'
        }
      ],
      employee_role: ""
    };
  },
  methods: {
    async getUserPermission() {
      let UserPermission = JSON.parse(localStorage.getItem("userPermission"));
      UserPermission.forEach((element) => {
        if (element == "managesalary") {
          this.ManageSalaryPermission = true;
        }
      });
    },
    importExcel() {
      this.$router.push({ name: "employee.importData" }).catch(() => { });
    },
    handleDepartment(department_id) {
      this.department_id = department_id;
      this.fetchEmployee(this.department_id);
      this.pagination.current_page = 1;
    },

    handleBranch(branch_id) {
      this.branch_id = branch_id;
      this.fetchEmployee(this.branch_id);
      this.pagination.current_page = 1;
    },

    showAllEmployeeItem(employeeId) {
      this.employee = {
        ...this.filterEmployee(employeeId.id),
      };
      this.modalShowAllEmployeeItem = true;
    },

    onChangeEmployeeStatus(employee_id) {
      this.employeeId = employee_id;
      this.modalUpdateEmployeeStatus = true;
    },
    /**
     * change status employee pending to active
     */
    onChangeEmployeeToActive(resigned_id) {
      this.resigned_id = resigned_id;
      this.modalUpdateEmployeeStatusToActive = true;
    },
    searchFilterItem() {
      this.fetchEmployee();
      this.pagination.current_page = 1;
    },
    filterEmployee(employeeId) {
      return (
        this.listEmployee.filter((item) => {
          return item.id == employeeId;
        })[0] || {}
      );
    },
    onCreate() {
      this.$router.push({ name: "employee.create" }).catch(() => { });
    },

    exportExcel() {
      this.isLoading = true;
      this.$axios
        .get(`export/employees/to/excel`, {
          params: {
            filter: this.searchItem,
            department_id: this.department_id,
            branch_id: this.branch_id,
            start_date: this.start_date
          },
        })
        .then((res) => {
          this.isLoading = false;
          this.$router
            .push({ name: "company.tracking-export-excel" })
            .catch(() => { });
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },

    onEdit(item) {
      this.$store.commit("Employee/EMPLOYEE_ID", item);
      this.$router.push({
        name: "employee.edit",
        params: {
          employee_id: item,
        },
      });
    },

    onDeleted(employeeId) {
      this.employeeId = employeeId;
      this.$store.commit("modalDelete_State", true);
    },

    onUpdateStatus(employeeId) {
      this.employeeId = employeeId;
      this.$store.commit("modalConfirm_State", true);
    },

    fetchEmployee() {
      let items = {
        page: this.pagination.current_page,
        per_page: this.per_page,
        filter: this.searchItem,
        department_id: this.department_id,
        branch_id: this.branch_id,
        start_date: this.start_date,
        end_date: this.end_date,
        employee_role: this.employee_role
      };
      this.isLoading = true;
      this.$axios
        .get(`company/list-employee`, {
          params: items,
        })
        .then((res) => {
          if (res.data.code === 200) {
            this.isLoading = false;
            this.listEmployee = res.data.data.data;
            this.pagination = res.data.data.pagination;
            if (!this.listEmployee.length > 0) {
              this.pagination.current_page = this.pagination.current_page - 1;
            }
          }
        })
        .catch(() => {
          this.isLoading = false;
        });
    },

    manageEmployee(employee_id) {
      const { addTooltip } = this.$refs;
      if (addTooltip && addTooltip[0]) {
        addTooltip.forEach(function (item) {
          item.removeTooltip();
        });
      }
      setTimeout(() => {
        this.$router
          .push({
            name: "manageResume.index",
            params: {
              employee_id: employee_id,
            },
          })
          .catch(() => { });
      });
    },
    fetchDepartment() {
      this.$axios.get(`company/list/departments/selected`, {}).then((res) => {
        if (res.status === 200) {
          this.listDepartment = res.data.data;
        }
      });
    },
    fetchBranch() {
      this.$axios
        .get(`company/list/company/branches`)
        .then((res) => {
          if (res) {
            this.listBranch = res.data.companyBranches;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getCompanyProfile() {
      this.$axios
        .get(`company/my-profile`)
        .then((res) => {
          localStorage.setItem(
            "sng-company-profile",
            JSON.stringify(res.data.data)
          );
        })
        .catch(() => { });
    },
  },
  created() {
    this.getUserPermission();
    this.getCompanyProfile();
    this.fetchDepartment();
    this.fetchBranch();
    this.fetchEmployee();
  },
};
</script>

<style scoped lang="scss">
.search {
  input {
    padding: 13px;
  }
}

.section-btn {
  .btn-export {
    background: rgb(67, 184, 69);
    color: white;
  }

  .btn-import {
    background: rgb(111, 153, 231);
    color: white;
  }

  .btn-create {
    color: rgb(255, 255, 255);
    background: #5dade2;
    font-family: $font-family;
  }
}

.fullTime {
  display: flex;
  align-items: center;

  i {
    color: #70f7bd;
    font-size: 8px;
    padding: 0 5px;
  }
}

.partTime {
  display: flex;
  align-items: center;

  i {
    color: #f5b556;
    font-size: 8px;
    padding: 0 5px;
  }
}

.employee-name {
  font-size: 16px;
  font-family: $font-family;
  font-weight: bold;
  color: rgb(0, 0, 0, 0.8);

  span {
    margin: 0 2px;
  }
}

.employee-position {
  font-size: 12px;
  font-family: $font-family;
  font-weight: lighter;
  color: #919191;

  i {
    padding: 0 5px;
  }
}

.position {
  span {
    position: relative;
    border-radius: 30px;
    font-size: 14px;
    // padding: 3px 18px;
    font-family: $font-family;
    letter-spacing: 1px;
    text-transform: capitalize;
    //background-color: rgba(30, 144, 255,0.4);
    color: #606060;
    font-weight: 300;

    .length-position {
      position: absolute;
      font-size: 12px;
      padding: 0.5px 6px;
      right: -35px;
      top: 0px;
      color: white;
      background-color: rgba(30, 144, 255, 0.4);
      cursor: pointer;
      transition: ease-out 0.2s;
    }

    .length-position:hover {
      transform: translateY(-10%);
    }
  }
}

.tooltip-la {
  font-family: $font-family;
}

.employee-exit {
  background-color: rgba(127, 140, 141, 0.1);
}

.leave-soon {
  background-color: rgba(254, 202, 87, 1);
  width: min-content;
  margin-left: 40px;
  color: #ffffff;
  padding: 2px 8px;
  font-size: 13px;
  border-radius: 30px;
  cursor: pointer;
}
</style>
